import React, { useEffect }  from 'react'
import * as S from '../pesquisa-satisfacao/styles'
import { graphql } from 'gatsby'
import Layout from '../../../components/totem/layout'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'

const PesquisaSatisfacaoTotem = ({ pageContext: { loja } }) => {

  useEffect(() => {
    ditoTrack('acessou-nps')
  }, [])

 
  return (
    <Layout loja={loja}>
      <S.PesquisaSatisfacaoTotem>
        <div>
          <iframe
            src="https://myvisit-br.asics.com/"
            width={650}
            height={810}
            title="Asics - Pesquisa de Satisfação"
            loading="eager"
            allow="accelerometer;"
          ></iframe>
        </div>
      </S.PesquisaSatisfacaoTotem>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}

PesquisaSatisfacaoTotem.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default PesquisaSatisfacaoTotem

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
